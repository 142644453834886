import * as React from "react"
import { navigate } from "gatsby"
import {
  useGetCart,
  useCompleteCart,
  useUpdatePaymentSession,
} from "medusa-react"
import Lottie from "react-lottie"
import { Flex, Text } from "theme-ui"
import qs from "query-string"

import SEO from "../../components/seo"
import { useStore } from "../../context/NewStoreContext"
import { useAdyenAdditionalDetails } from "../../hooks/adyen/adyen"

const PaymentCompleted = () => {
  const store = useStore()
  const cartQuery = useGetCart(store.cart?.id, {
    enabled: !!store.cart?.id,
  })
  const adyenAdditionalDetailsMutation = useAdyenAdditionalDetails({
    cartId: store.cart?.id,
  })
  const updateSession = useUpdatePaymentSession(store.cart?.id)
  const completeCartMutation = useCompleteCart(store.cart?.id, {
    onSuccess: (data) => {
      if (data.type === "order") {
        store.clearCart()

        navigate(`/checkout/done?o=${data.data.id}`, {
          replace: true,
        })
      }
    },
    onError: () => {
      navigate(`/checkout?paymentRefused=error`)
    },
    retry: 2,
  })

  React.useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault()
      return "Are you sure you want to leave?"
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  React.useEffect(() => {
    const { redirectResult } = qs.parse(window.location.search)

    if (redirectResult) {
      if (cartQuery.isSuccess && adyenAdditionalDetailsMutation.isIdle) {
        adyenAdditionalDetailsMutation.mutate(
          {
            paymentMethodData: redirectResult,
          },
          {
            onSuccess: (res) => {
              if (res?.resultCode === "Refused") {
                navigate(`/checkout?paymentRefused=${res?.refusalReasonCode}`)
              }

              updateSession.mutate(
                {
                  provider_id: "adyen",
                  data: res,
                },
                {
                  onSuccess: () => {
                    completeCartMutation.mutate()
                  },
                  onError: () => {
                    navigate("/checkout?paymentRefused=10")
                  },
                }
              )
            },
          }
        )
      }
    } else if (cartQuery.isSuccess && completeCartMutation.isIdle) {
      if (cartQuery.cart.context?.split_order) {
        navigate(`/checkout/done-split-order?so=${cartQuery.cart.id}`, {
          replace: true,
        })
      } else {
        completeCartMutation.mutate()
      }
    }
  }, [cartQuery.isSuccess, completeCartMutation])

  return cartQuery.isLoading || completeCartMutation.isLoading ? (
    <>
      <SEO title="Payment completed" />
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Lottie
          options={{
            autoplay: true,
            loop: true,
            animationData: require("../../assets/lottie/loader.json"),
          }}
          style={{
            width: 112,
            height: 112,
            marginBottom: 20,
          }}
        />
        <Text
          sx={{
            fontSize: "xl",
            textAlign: "center",
          }}
        >
          Order is {`\n`}being processed ...
        </Text>
      </Flex>
    </>
  ) : null
}

export default PaymentCompleted
